import { useEffect, useState } from 'react'
import { makeStyles } from '../../core/utils/theme';
import { Box, Button, TableHead, Tooltip } from '@mui/material'
import useSettings from '../../hooks/useSettings'
import useUser from '../../hooks/useUser'
import useApp from '../../hooks/useApp'
import { observer } from 'mobx-react';
import { IDataRecipe } from '../../core/types/source_service/IDataRecipe';
import NeedMoreDataCakesModal from '../Modals/NeedMoreDataCakesModal';
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import PeopleIcon from '@mui/icons-material/People'

const useStyles = makeStyles()(() => ({
  table: {
    // width:'100%',
    minWidth:'100%',
    maxWidth:'100%',
    border:"1px black solid",
    '& td': {
      border:'0',
    },
  },
  tr: {
  },
  tdactive: {
    color:'darkblue',
  },
  tdleft: {
    textAlign:'center',
  },
  tdcenter: {
    minWidth:'40%'
  },
  tdright: {
  },
  switch: {
    width:'100%',
    height:'100%',
    visibility:'hidden',
  },
  button: {
    color:'grey',
    borderColor:'grey',
    '&:hover': {
      color:'darkblue',
      borderColor:'darkblue'
    },
    margin: '0 4px 0 4px'
  },
  icon: {
    color: 'grey',
    '&:hover': {
      color: 'darkblue'
    },
    marginLeft: '4px',
    marginRight: '4px'
  },
  hidden: {
    visibility:'hidden'
  },
  bakeButton: {
    width: '100%',
    color:'black',
    border: '1px solid black',
    padding: '10px 14px',
    margin: '24px 0 0 0',
    cursor:'pointer',
    textAlign: 'center',
    '&:hover': {
      background:'#eee'
    }
  }
}))

const Datacakes = observer(() => {
  const { classes, cx } = useStyles()
  const settings = useSettings();
  const user = useUser()
  const app = useApp()
  // const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [isContactUsModalOpen, setIsContactUsModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [cakeIdToBeDeleted, setCakeIdToBeDeleted] = useState('')

  useEffect(() => {
    settings
      .loadRecipesByCurrentUser()
      .then(() => {
        console.log('Data receipes loaded');
      })
      .catch((err) => console.log('Unable to load recipes', err));
  }, [])

  const handleDeleteModalClose = () => {
    setCakeIdToBeDeleted('')
    settings.setIsDatacakesModalOpen(true)
    setIsDeleteModalOpen(false)
  }

  const handleEdit = () => {
    console.log('editing')
  }
  const handleDuplicate = () => {
    console.log('duplicating')
  }

  const handleMouseEnter = (i: number) => {
    const box = document.getElementById(`box_${i}`)
    if (box) box.style['visibility']='visible'
    const del = document.getElementById(`del_${i}`)
    if (del) del.style['visibility']='visible'
  }

  const handleMouseLeave = (i: number) => {
    const box = document.getElementById(`box_${i}`)
    if (box) box.style['visibility']='hidden'
    const del = document.getElementById(`del_${i}`)
    if (del) del.style['visibility']='hidden'
  }

  const handleSwitch = (item: IDataRecipe) => {
    app.resetCubie
    settings.setIsDatacakesModalOpen(false)
    if (item && item.cake_id) {
      app.setCakeId(item.cake_id)
      app.setTitle(item.title || '')
      settings.reportState(item.cake_id)
      app.setDashboardItems([])
      app.loadDashboardItems(item.cake_id)
      app.updateSources()
    }
  }

  const handleTrashClick = (i: number) => {
    setCakeIdToBeDeleted(settings.dataRecipes[i].cake_id || '')
    setIsDeleteModalOpen(true)
  }

  const handleDelete = async () => {
    if (cakeIdToBeDeleted) {
      await settings.deleteSelectedDataRecipeById(cakeIdToBeDeleted)
      // Refetch the recipes
      await settings.loadRecipesByCurrentUser()
    } else {
      Error("There was a problem deleting the datacake.")
    }
    setIsDeleteModalOpen(false)
  }

  const handleOpenCreateRecipeModal = () => {
    settings.setIsDatacakesModalOpen(false)
    if (!settings.hasDataSources) {
      settings.setIsDataSourcesModalOpen(true)
      settings.setIsCreateDataRecipeModalOpen(false)
      return
    }
    if (!user.isInsider() && settings.dataRecipes && settings.dataRecipes.length >= 1) {
      setIsContactUsModalOpen(true);
      return
    }

    settings.setIsCreateDataRecipeModalOpen(true, true)
  }

  const getContent = () => {
    if (!settings.dataRecipes)
      return (<Box>No Datacakes</Box>)
    else
      return (
        <Box className="w-[100%]">
          <Table className={classes.table}>
            <TableHead sx={{borderColor:'black'}}>
              <TableRow>
                <TableCell><DeleteForeverIcon className={classes.icon} style={{visibility:'hidden'}}/></TableCell>
                <TableCell className={classes.tdcenter}>Cake Name</TableCell>
                <TableCell className={classes.tdleft}>Active?</TableCell>
                <TableCell className="text-center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {settings.dataRecipes?.map((item: IDataRecipe, i: number) => {
              return (app.cakeId === item.cake_id) ?
                  <TableRow key={i} sx={{backgroundColor:'#eef'}}>
                    <TableCell><DeleteForeverIcon id={`del_${i}`} className={classes.icon} onClick={() => handleTrashClick(i)}/></TableCell>
                    <TableCell className={classes.tdcenter} ><b>{item.name}</b></TableCell>
                    <TableCell className={cx(classes.tdleft, classes.tdactive)}><b>Active</b></TableCell>
                    <TableCell className="flex justify-center">
                      <Tooltip title="Edit datacake info (coming soon)" >
                        <EditIcon className={classes.icon} onClick={handleEdit}/>
                      </Tooltip>
                      <Tooltip title="Duplicate datacake (coming soon)" >
                        <ContentCopyIcon className={classes.icon} onClick={handleDuplicate}/>
                      </Tooltip> 
                      {user.isInsider() && 
                      <Tooltip title="Manage users (coming soon)" >
                        <PeopleIcon className={classes.icon} />
                      </Tooltip>                       
                      }
                    </TableCell>
                  </TableRow> : null
            })}
            {settings.dataRecipes?.map((item: IDataRecipe, i: number) => {
              return (app.cakeId !== item.cake_id) ?
                  <TableRow key={i} hover={true} onMouseEnter={()=>handleMouseEnter(i)} onMouseLeave={()=>handleMouseLeave(i)}>
                    <TableCell><DeleteForeverIcon id={`del_${i}`} className={cx(classes.icon, classes.hidden)} onClick={() => handleTrashClick(i)}/></TableCell>
                    <TableCell className={classes.tdcenter}>{item.name}</TableCell>
                    <TableCell className={classes.tdleft}>
                      <Box className={classes.switch} id={`box_${i}`}>
                        <Button variant='outlined' size='small' className={classes.button} onClick={()=>handleSwitch(item as IDataRecipe)}>Switch</Button>
                      </Box>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow> : null
            })}
            </TableBody>
          </Table>
        </Box>
      )}

  return (
    <Box className='w-full'>
      <NeedMoreDataCakesModal open={isContactUsModalOpen} onClose={() => setIsContactUsModalOpen(false)} />
      <DeleteConfirmationModal
            open={isDeleteModalOpen} onClose={handleDeleteModalClose}
            title="Delete"
            type='recipe'
            description='You are about to delete a Datacake Recipe!'
            onDelete={handleDelete}
          />
      {getContent()}
      <Box className={classes.bakeButton} onClick={handleOpenCreateRecipeModal}>
        Bake a New Datacake
      </Box>
    </Box>
  )
})

export default Datacakes
