import { observer } from 'mobx-react'
import { FC, useEffect, useState } from 'react'
import useSettings from '../../hooks/useSettings'
// import useUser from '../../hooks/useUser'
import { makeStyles } from '../../core/utils/theme'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import Loader from '../Misc/Loader'
import { fetchAnalyticsProperties, updateDataSourceActive } from '../../core/services/source_service'
import { TBackendResponse } from '../../core/types/TBackendResponse'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: '2rem',
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'space-between',
    gridTemplateRows: '90px 55vh max-content',
    paddingBottom: '2rem',
  },
  innerContainer: {
    margin: '1.5rem 0',
    minHeight: '55vh',
    maxWidth: '72rem',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: '2rem',
  },
  note: {
  },
  description: {
    fontWeight: 300,
  },
  loggedInContainer: {
    marginBottom: '1.25rem',
    marginTop: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2rem',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    fontWeight: 500,
  },
  loader: {
    margin: '0 auto',
    display: 'block',
    width: '10rem',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  loaderContainer: {
    height: '30px',
  },
  userEmail: {
    color: theme.palette.text.primary,
  },
  authContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',

    [theme.breakpoints.down(1921)]: {
      marginTop: '-20px',
    },
  },
  authProviderContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    padding: '20px 0',
    [theme.breakpoints.down(1921)]: {
      padding: '0px 0',
      marginBottom: '10px',
    },
  },
  authBtn: {
    display: 'flex',
    borderWidth: '0 !important',
    padding: '0',
  },
  nextBtn: {
    marginTop: '2rem',
    alignSelf: 'flex-start',
  },
  datasetContainer: {
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '3rem',

    [theme.breakpoints.down(1921)]: {
      marginTop: '0rem',
    },
  },
  dataset: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    maxWidth: '140px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '72px',
    },

    [theme.breakpoints.down(1921)]: {
      transform: 'scale(0.8)',
    },
  },
  datasetIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '96px',
    height: '96px',
    background: '#EFEFEF',
    [theme.breakpoints.down('md')]: {
      width: '72px',
      padding: '1rem',
      height: '72px',
    },
  },
  datasetTitle: {
    marginTop: '0.5rem',
    color: theme.palette.text.disabled,
    lineHeight: '1.25rem',
    textAlign: 'center',
  },
  buttonContainer: {
    width: '100%', // Ensure the container takes the full width
    display: 'flex',
    justifyContent: 'space-between', // Spread out buttons
    marginTop: 'auto', // Pushes the button container to the bottom
  },
  proceedButton: {
    flex: 1,
    backgroundColor: theme.palette.primary.main, // Or any color that stands out
    color: '#000000', // Usually, a text on a button is white for better contrast
    fontWeight: 'bold', // Makes the text bold
    border: '1px solid #000',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, // Darker shade for hover state
      color: '#ffffff', // Keep text color white on hover for contrast
    },
    '&:disabled': {
      backgroundColor: theme.palette.action.disabledBackground, // Style for disabled state
      color: theme.palette.action.disabled, // Text color for disabled state
    },
  },
}))

interface IProps {
}
const DataSourceManageGoogleAnalytics: FC<IProps> = observer(() => {
  // const user = useUser()
  const settings = useSettings()
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState<object[]>([])
  const [active, setActive] = useState<object[]>([])
  const [error, setError] = useState<string | null>(null)
  let accts_dict: any = Object()
  let props_dict: any = Object()
  // let active: string[] = []

  useEffect(() => {
    if (properties.length == 0) {
      const fetchAProps = async () => {
          try {
            console.log('fetching customer ids')
            setLoading(true)
            const response = await fetchAnalyticsProperties(settings.dataSourceIdBeingManaged||'')
            setLoading(false)
            console.log('response', response)
            // Extract customer IDs from the response and update the state
            setProperties(response.data.properties)
            const actv: object[] = response.data.active || []
            setActive(actv.filter((a) => typeof(a)=='object'))
          } catch (error) {
            // Handle the error
            console.error('Error fetching customer IDs:', error);
          }
      }

      console.log("fetching analytics properties")
      fetchAProps()
    }
  }, [active])

  // Handler for when a new customer ID is selected
  const handlePropertySelection = async (p: any) => {
    let newActive: object[] = []
    let s = new Set(active.map((a: any)=>a.property_id))
    console.log('before: active=', active)
    if (s.has(p.property_id)) {
      s.delete(p.property_id)
      newActive = active.filter((a: any)=> s.has(a.property_id))
    } else {
      s.add(p.property_id)
      newActive = properties.filter((prop: any)=> s.has(prop.property_id))
    }

    try {
      console.log('Writing to redis:', active)
      const resp = await updateDataSourceActive(settings.dataSourceIdBeingManaged || '', newActive)
      const response = resp as TBackendResponse<any>
      if (response.status === 'ok') {
        setActive(newActive)
        settings.fetchNeededforTableDetails = true
      } else {
        // Handle non-'ok' status
        setError('updateDataSourceActive failed: ' + (response || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error adding data sources:', error);
      setError('setProp} failed: ' + (error || 'Unknown error'));
    }
    console.log("after: active=", active)
  }

  properties.forEach((p: any) => {
    if (!accts_dict[p.account_id])
      accts_dict[p.account_id] = p.account_name
    if (!props_dict[p.account_id])
      props_dict[p.account_id as keyof Object] = Object()
    props_dict[p.account_id][p.property_id] = p
  })

  return (
    <Box className={classes.root}>
      <Box className={classes.innerContainer}>
        <Typography variant="h6" className={classes.description}>
          Properties
        </Typography>
        <Typography variant="body1" className={classes.description}>
          Choose which properties's data to connect to Cubie.
        </Typography>
        <Box className={classes.loaderContainer}>
          {loading && <Loader className={classes.loader} />}
          {error && <div style={{ color: 'red' }}>{String(error)}</div>}
        </Box>
        <Box className={classes.authContainer}>
            <div>
              {Object.keys(props_dict).map((acct_id) => (
              <div key={acct_id}>
                {accts_dict[acct_id]}
                {Object.keys(props_dict[acct_id as keyof Object]).map(
                  (prop_id: string) => {
                    const p = props_dict[acct_id as keyof Object][prop_id]
                    const isActive = active.map((a:any)=>a.property_id).includes(p.property_id)
                    return (
                      <div key={p.property_id}>
                        <FormControlLabel value={p.property_id}
                          control={
                            <Checkbox
                              sx={{color:'black'}}
                              checked={isActive}
                              onClick={()=>handlePropertySelection(p)}
                            />
                          }
                          label={`${p.property_id} (${p.property_name})`}
                        />
                      </div>
                    )
                  }
                )}
              </div>
              ))}
            </div>
        </Box>
      </Box>
      <Box className={classes.note}>
        <Typography variant="body1" className={classes.description}>
          Note that you must have the necessary permissions to create queries for that dataset.
        </Typography>
      </Box>
    </Box>
  )
})

export default DataSourceManageGoogleAnalytics
